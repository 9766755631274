import React from 'react';
import { Link } from "gatsby"

import './header.scss';
export default function Header() {
    return (
        <header>
            <Link to="/">
                Vornicoglo
            </Link>
            <Link to="/projects">Projects</Link>
        </header>
    )
}