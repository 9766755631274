import React from "react";
import { Link } from "gatsby";
import { cloudinaryImageQualityModify } from "../utils/utils";

export default function ProjectPreview(props) {
    return (
        <li>
            <Link to={props.path}>
                <img width="300" height="200" src={cloudinaryImageQualityModify(props.cover)} />
                <span>{props.title}</span>
            </Link>
        </li>
    )
}