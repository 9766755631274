import React from "react";
import { cloudinaryImageQualityModify } from "../utils/utils"

export default function Cover(props) {
    return (
        <div className="project__cover"
            style={{
                backgroundImage: `url(${cloudinaryImageQualityModify(props.cover)})`,
                height: `${props.height ? props.height : '100vh'}`
            }}
        >
            <h1 className="project__title">{props.title}</h1>
        </div>
    )
}